import React from 'react'
import Header2 from '../../components/shared/Header2'
import Footer2 from '../../components/shared/Footer2'
import top1 from '../../assets/new-image/hero-right.png';
import top2 from '../../assets/new-image/hero-left.png';
import question from '../../assets/about/question.png';
import girl from '../../assets/about/girl.png';
import foldright from '../../assets/about/fold-right.png';
import foldleft from '../../assets/about/fold-left.png';
import top3 from '../../assets/new-image/new-bg.png';
import icon1 from '../../assets/about/bar_chart.png';
import icon2 from '../../assets/about/rocket_launch.png';
import icon3 from '../../assets/about/spa.png';
import icon4 from '../../assets/about/star_rate.png';
import icon5 from '../../assets/about/work.png';

function AboutNew() {

  const [whyusetb, setwhyusetb] = React.useState([
    {
      name: "End-to-End Talent Solutions ",
      text: "From recruitment to retention, manage every stage of the employee lifecycle on a single platform."
    },
    {
      name: "Cultural Fitment Analysis",
      text: "Identify candidates who align with your organisational values for better collaboration and long-term success."
    },
    {
      name: "Upskilling &amp; Reskilling",
      text: "Equip your workforce with skills that matter, ensuring adaptability in dynamic market conditions."
    },
    {
      name: "Skill Profiling ",
      text: "Leverage data-driven insights to identify employee potential and map career growth pathways."
    },
    {
      name: "Seamless Integration",
      text: "Third Bracket works effortlessly with your existing systems, providing a unified and user-friendly experience."
    }
  ])
  return (
    <div>
      <Header2 />

      {/* about banner start*/}
      <section className="bg-banner-home relative " >
        <div className='max-w-7xl lg:px-0 px-4 mx-auto'>
          <div className="flex lg:flex-row flex-col items-center justify-between gap-8 lg:py-32 py-10">
            <div className='flex-1'>
              <h3 className='font-semibold ff-pop lg:text-4xl xl:text-[46px] leading-[1.2] mb-[18px] text-[28px] text-[#191919] lg:max-w-full text-center'>
                What is Third Bracket?
              </h3>
              <p className='font-normal lg:text-lg text-center text-base tracking-[-3%] text-[#40566D] lg:max-w-[900px] mx-auto  mt-9  ff-inter'>Third Bracket is an advanced SaaS platform designed to revolutionize the way organizations manage their employee lifecycle. From hiring the right talent and ensuring cultural fitment to upskilling employees and profiling their skills for future growth.</p>
              <p className='font-normal lg:text-lg text-center text-base tracking-[-3%] text-[#40566D] lg:max-w-[900px] mt-2.5 mx-auto ff-inter'>
                Third Bracket seamlessly integrates technology, data, and strategy to help organizations build future-ready workforces. Our platform empowers businesses to optimize every touchpoint in the employee journey, enabling smarter decisions, enhanced engagement, and measurable outcomes.
              </p>
            </div>
          </div>
        </div>
        <div className='absolute lg:top-24 lg:left-20 left-2 top-2 lg:w-[126px] w-12'>
          <img src={question} alt="question-mark" width={126} height={170} />
        </div>
        <div className='absolute lg:top-16 top-3 rotate-45 lg:right-24 right-4 lg:w-12 w-9'>
          <img src={question} alt="question-mark" width={48} height={67} />
        </div>
        <div className='absolute -bottom-1.5  left-2/3 lg:w-12 w-9'>
          <img src={question} alt="question-mark" width={48} height={67} />
        </div>
        <div className='top-image-1 lg:block hidden w-full absolute lg:-top-1/3 left-0 z-[-1]'>
          <img src={top3} className='w-full h-auto' alt="" />
        </div>
      </section>
      {/* about banner end */}

      {/* Why Use Third Bracket start */}
      <div className='bg-[#FFC727] lg:pt-36 md:pt-14 pt-10 px-4'>
        <h3 className='font-semibold ff-pop lg:text-4xl xl:text-[46px] leading-[1.2] mb-[18px] text-[28px] text-[#191919] lg:max-w-full text-center'>
          Why Use Third Bracket?
        </h3>
        <p className='font-normal lg:text-lg text-center text-base tracking-[-3%] text-[#40566D] lg:max-w-[560px] mx-auto mt-5 lg:mt-10  ff-inter'>
          In today’s fast-evolving workplace, businesses need more than just tools—they need smart, adaptive solutions. Third Bracket offers
        </p>
        <div className='lg:mt-16 mt-8 relative'>

          <div className='flex flex-row  flex-wrap items-stretch justify-center lg:gap-x-[42px] lg:gap-y-[50px] gap-8 max-w-[1050px] mx-auto'>
            {
              whyusetb.map((item, index) => {
                return (
                  <div className='rounded-[10px] bg-[#FFDF82] py-9 px-[30px] lg:w-[30%] md:w-1/2 w-full' key={index}>
                    <h3 className='my-0 ff-inter font-bold lg:text-lg text-base text-center text-[#191919]'>{item.name}</h3>
                    <p className='my-0 ff-inter font-normal lg:text-lg text-base text-center text-[#626262]'>{item.text}</p>
                  </div>
                )
              })
            }

          </div>
          <div className='flex flex-row items-center justify-center w-full lg:w-[800px] mx-auto mt-2'>
            <img src={girl} alt="girl-climbing" width={800} height={800} className='w-full h-auto' />
          </div>
          <div className='w-[326px] 2xl:w-[300px] absolute -bottom-20 -right-5 lg:block hidden'>
            <img src={foldright} width={326} alt="fold-right" className='w-full h-auto' />
          </div>
          <div className='w-[326px] 2xl:w-[500px] absolute bottom-0 left-0 lg:block hidden'>
            <img src={foldleft} width={500} alt="fold-right" className='w-full h-auto' />
          </div>
        </div>
      </div>
      {/* Why Use Third Bracket end */}

      {/* About Us start */}
      <div className='relative overflow-hidden px-4 lg:px-0'>
        <div className='bg-no-repeat bg-contain bg-top py-10 lg:py-32 bg-none-1' style={{ backgroundImage: `url(${top3})` }}>
          <h3 className='font-semibold ff-pop lg:text-4xl xl:text-[46px] leading-[1.2] mb-[18px] text-[28px] text-[#191919] lg:max-w-full text-center'>
            How Will Your Organization Benefit?
          </h3>
          <p className='font-normal lg:text-lg text-center text-base tracking-[-3%] text-[#40566D] lg:max-w-[650px] mx-auto  mt-10  ff-inter'>
            Third Bracket leverages advanced Artificial Intelligence (AI) to revolutionise employee skill development by identifying capability and competency gaps with
            precision. Here’s how
          </p>

          <div className='flex flex-col lg:flex-row items-stretch  mt-8 lg:mt-20 justify-between gap-8 lg:w-[1050px] mx-auto'>
            <div className='bg-[#F6F6F6] rounded-[10px] py-[50px]  px-6 relative w-full lg:w-[300px]'>
              <div className='flex flex-col justify-center items-center gap-4'>
                <img src={icon5} alt="icon1" width={50} height={50} />
                <h3 className='font-bold text-[#191919] ff-inter lg:text-lg text-center'>Hire Smarter</h3>
              </div>
              <p className='font-normal text-[#626262] ff-inter lg:text-lg text-base mt-1 text-center'>Reduce hiring time and costs by finding top talent that aligns with your technical needs and cultural values.</p>
              <div className='bg-[#FFC727] rounded-[10px] h-3.5 bottom-0 absolute w-full left-0'></div>
            </div>
            <div className='bg-[#F6F6F6] rounded-[10px] py-[50px]  px-6 relative w-full lg:w-[300px]'>
              <div className='flex flex-col justify-center items-center gap-4'>
                <img src={icon2} alt="icon1" width={50} height={50} />
                <h3 className='font-bold text-[#191919] ff-inter lg:text-lg text-center'>Boost Productivity</h3>
              </div>
              <p className='font-normal text-[#626262] ff-inter lg:text-lg text-base mt-1 text-center'>Upskill employees effectively and align their growth with organizational goals.</p>
              <div className='bg-[#FFC727] rounded-[10px] h-3.5 bottom-0 absolute w-full left-0'></div>
            </div>
          </div>
          <div className='flex flex-col lg:flex-row items-stretch justify-center my-8 lg:w-[1050px] mx-auto'>
            <div className='bg-[#F6F6F6] rounded-[10px] py-[50px]  px-6 relative w-full lg:w-[300px]'>
              <div className='flex flex-col justify-center items-center gap-4'>
                <img src={icon4} alt="icon1" width={50} height={50} />
                <h3 className='font-bold text-[#191919] ff-inter lg:text-lg text-center'>Stay Future-Ready</h3>
              </div>
              <p className='font-normal text-[#626262] ff-inter lg:text-lg text-base mt-1 text-center'>Build agile teams equipped to navigate industry shifts and competitive landscapes.</p>
              <div className='bg-[#FFC727] rounded-[10px] h-3.5 bottom-0 absolute w-full left-0'></div>
            </div>
          </div>
          <div className='flex flex-col lg:flex-row items-stretch justify-between gap-8 lg:w-[1050px] mx-auto'>
            <div className='bg-[#F6F6F6] rounded-[10px] py-[50px]  px-6 relative w-full lg:w-[300px]'>
              <div className='flex flex-col justify-center items-center gap-4'>
                <img src={icon3} alt="icon1" width={50} height={50} />
                <h3 className='font-bold text-[#191919] ff-inter lg:text-lg text-center'>Enhance Engagement</h3>
              </div>
              <p className='font-normal text-[#626262] ff-inter lg:text-lg text-base mt-1 text-center'>Foster a thriving workplace by focusing on cultural fit and personalized development.</p>
              <div className='bg-[#FFC727] rounded-[10px] h-3.5 bottom-0 absolute w-full left-0'></div>
            </div>
            <div className='bg-[#F6F6F6] rounded-[10px] py-[50px]  px-6 relative w-full lg:w-[300px]'>
              <div className='flex flex-col justify-center items-center gap-4'>
                <img src={icon1} alt="icon1" width={50} height={50} />
                <h3 className='font-bold text-[#191919] ff-inter lg:text-lg text-center'>Drive Data-Backed Decisions</h3>
              </div>
              <p className='font-normal text-[#626262] ff-inter lg:text-lg text-base mt-1 text-center'>Utilize comprehensive insights and analytics for workforce planning and management.</p>
              <div className='bg-[#FFC727] rounded-[10px] h-3.5 bottom-0 absolute w-full left-0'></div>
            </div>
          </div>
        </div>

      </div>
      <Footer2 />
    </div>
  )
}

export default AboutNew
import { useState } from 'react';
import plus from '../../assets/new-home/plus.svg';

const questions = [
    {
        question: "What existing HR tools or software can Third Bracket integrate with?",
        answer: (
            <>
                <div>
                    <b className='ff-mulish font-bold lg:text-lg text-sm leading-tight text-[#061927]'>Seamless Integration with Your Existing HR Ecosystem</b>
                    <p className='ff-mulish font-medium my-4 lg:text-lg text-sm leading-tight text-[#061927]'> Third Bracket is designed to work effortlessly with a wide range of HR tools and software, ensuring a seamless experience for your organization. Our platform integrates with:
                    </p>
                </div>
                <ul className='list-disc pl-5 space-y-2'>
                    <li className='ff-mulish font-medium my-2 lg:text-lg text-sm leading-tight text-[#061927]'><strong className='ff-mulish'>Applicant Tracking Systems (ATS):</strong> Easily sync hiring data from popular ATS platforms like Workday, Greenhouse, and Lever.</li>
                    <li className='ff-mulish font-medium my-2 lg:text-lg text-sm leading-tight text-[#061927]'><strong className='ff-mulish'>Human Resource Management Systems (HRMS):</strong> Connect with systems such as SAP SuccessFactors, BambooHR, and Zoho People.</li>
                    <li className='ff-mulish font-medium my-2 lg:text-lg text-sm leading-tight text-[#061927]'><strong className='ff-mulish'>Learning Management Systems (LMS):</strong> Integrate with platforms like Moodle, Coursera for Business, or Udemy for Business.</li>
                    <li className='ff-mulish font-medium my-2 lg:text-lg text-sm leading-tight text-[#061927]'><strong className='ff-mulish'>Performance Management Tools:</strong> Sync with 15Five, Lattice, or ClearCompany for performance tracking.</li>
                    <li className='ff-mulish font-medium my-2 lg:text-lg text-sm leading-tight text-[#061927]'><strong className='ff-mulish'>Collaboration Platforms:</strong> Integrate with Slack, Microsoft Teams, and Zoom.</li>
                    <li className='ff-mulish font-medium my-2 lg:text-lg text-sm leading-tight text-[#061927]'><strong className='ff-mulish'>Payroll and Benefits Software:</strong> Work seamlessly with ADP, Gusto, or Paychex.</li>
                </ul>
            </>
        )
    },
    {
        question: "Is Third Bracket GDPR and CCPA compliant?",
        answer: (
            <>
                <div>
                    <b className='ff-mulish font-bold lg:text-lg text-sm leading-tight text-[#061927]'>Data Privacy and Compliance: GDPR & CCPA Compliant
                    </b>
                    <p className='ff-mulish font-medium my-4 lg:text-lg text-sm leading-tight text-[#061927]'> At Third Bracket, we prioritize the privacy and security of your data. We are fully compliant with both the General Data Protection Regulation (GDPR) and the California Consumer Privacy Act (CCPA), ensuring that your organization’s and employees’ information is handled with the highest standards of protection.
                    </p>
                    <strong className='ff-mulish font-bold lg:text-lg text-sm leading-tight text-[#061927] my-5 block'>GDPR Compliance
                    </strong>
                    <p className='ff-mulish font-medium my-4 lg:text-lg text-sm leading-tight text-[#061927]'> Third Bracket adheres to all GDPR requirements, including:
                    </p>
                </div>
                <ul className='list-disc pl-5 space-y-2'>
                    <li className='ff-mulish font-medium my-2 lg:text-lg text-sm leading-tight text-[#061927]'><strong className='ff-mulish'>Data Protection by Design and Default:</strong> Incorporating robust security measures into our platform architecture to safeguard personal data from the outset.</li>
                    <li className='ff-mulish font-medium my-2 lg:text-lg text-sm leading-tight text-[#061927]'><strong className='ff-mulish'>Lawful Basis for Processing:</strong> Ensuring all data processing activities have a legitimate basis, whether it be consent, contractual necessity, or legitimate interests.</li>
                    <li className='ff-mulish font-medium my-2 lg:text-lg text-sm leading-tight text-[#061927]'><strong className='ff-mulish'>Data Subject Rights:</strong> Facilitating the rights of individuals, such as access, rectification, erasure, and data portability, through user-friendly tools and processes.</li>
                    <li className='ff-mulish font-medium my-2 lg:text-lg text-sm leading-tight text-[#061927]'><strong className='ff-mulish'>Transparent Data Practices:</strong> Maintaining clear and comprehensive privacy policies that outline how data is collected, used, and protected.</li>
                </ul>
                <strong className='ff-mulish font-bold lg:text-lg text-sm leading-tight text-[#061927] my-5 block'>CCPA Compliance

                </strong>
                <p className='ff-mulish font-medium my-4 lg:text-lg text-sm leading-tight text-[#061927]'> Third Bracket meets all CCPA obligations, including:
                </p>
                <ul className='list-disc pl-5 space-y-2'>
                    <li className='ff-mulish font-medium my-2 lg:text-lg text-sm leading-tight text-[#061927]'><strong className='ff-mulish'>Consumer Rights:</strong> Empowering California residents with rights to access, delete, and opt-out of the sale of their personal information.</li>
                    <li className='ff-mulish font-medium my-2 lg:text-lg text-sm leading-tight text-[#061927]'><strong className='ff-mulish'>Transparency:</strong> Providing detailed disclosures about data collection practices, the categories of personal information collected, and the purposes for which it is used.</li>
                    <li className='ff-mulish font-medium my-2 lg:text-lg text-sm leading-tight text-[#061927]'><strong className='ff-mulish'>Data Security:</strong> Implementing advanced security protocols to protect personal information from unauthorized access, breaches, and other security threats.</li>
                    <li className='ff-mulish font-medium my-2 lg:text-lg text-sm leading-tight text-[#061927]'><strong className='ff-mulish'>Accountability:</strong> Maintaining records of data processing activities and ensuring all third-party partners adhere to CCPA standards.</li>
                </ul>
                <strong className='ff-mulish font-bold lg:text-lg text-sm leading-tight text-[#061927] my-5 block'>Our Commitment to Data Privacy

                </strong>
                <p className='ff-mulish font-medium my-4 lg:text-lg text-sm leading-tight text-[#061927]'> Third Bracket meets all CCPA obligations, including:
                </p>
                <ul className='list-disc pl-5 space-y-2'>
                    <li className='ff-mulish font-medium my-2 lg:text-lg text-sm leading-tight text-[#061927]'><strong className='ff-mulish'>Encryption and Security:</strong> Utilizing state-of-the-art encryption and security technologies to protect data both in transit and at rest.</li>
                    <li className='ff-mulish font-medium my-2 lg:text-lg text-sm leading-tight text-[#061927]'><strong className='ff-mulish'>Regular Audits and Assessments:</strong> Conducting frequent security audits and compliance assessments to identify and mitigate potential vulnerabilities.</li>
                    <li className='ff-mulish font-medium my-2 lg:text-lg text-sm leading-tight text-[#061927]'><strong className='ff-mulish'>Employee Training:</strong> Ensuring our team is well-versed in data protection laws and best practices through continuous training and education.</li>
                    <li className='ff-mulish font-medium my-2 lg:text-lg text-sm leading-tight text-[#061927]'><strong className='ff-mulish'>Incident Response:</strong> Maintaining a robust incident response plan to promptly address and manage any data breaches or security incidents.</li>
                </ul>
                <strong className='ff-mulish font-medium my-4 lg:text-lg text-sm leading-tight text-[#061927] block'>Why Choose Third Bracket for Data Compliance?
                </strong>
                <p className='ff-mulish font-medium my-4 lg:text-lg text-sm leading-tight text-[#061927]'>By partnering with Third Bracket, you can be confident that your employee data is managed in accordance with the strictest privacy regulations. Our commitment to GDPR and CCPA compliance not only protects your organization from legal risks but also builds trust with your employees by demonstrating our dedication to safeguarding their personal information.
                    **Trust Third Bracket to provide a secure, compliant, and reliable platform for managing your employee lifecycle with peace of mind.
                </p>
            </>
        )
    },
    {
        question: "Do you offer a free trial or demo of Third Bracket?",
        answer: (
            <div className='space-y-4'>
                <p className='ff-mulish font-medium lg:text-lg text-sm leading-tight text-[#061927]'>Yes, we offer both a free trial and a personalized demo of Third Bracket to help you experience the value our platform can bring to your organization.</p>
                <div>
                    <h4 className='font-semibold text-lg text-[#191919] ff-mulish'>Free Trial</h4>
                    <ul className='list-disc pl-5 space-y-2'>
                        <li className='ff-mulish font-medium my-4 lg:text-lg text-sm leading-tight text-[#061927]'>Explore our key features across hiring, cultural fitment, skill profiling, upskilling, and career development.</li>
                        <li className='ff-mulish font-medium my-4 lg:text-lg text-sm leading-tight text-[#061927]'>Experience our intuitive interface and seamless integrations with existing HR tools.</li>
                        <li className='ff-mulish font-medium my-4 lg:text-lg text-sm leading-tight text-[#061927]'>See how our data-driven insights optimize your employee lifecycle.</li>
                    </ul>
                </div>
                <div>
                    <h4 className='font-semibold text-lg text-[#191919] ff-mulish'>Personalized Demo</h4>
                    <ul className='list-disc pl-5 space-y-2'>
                        <li className='ff-mulish font-medium my-4 lg:text-lg text-sm leading-tight text-[#061927]'>Understand how Third Bracket can address your specific workforce challenges.</li>
                        <li className='ff-mulish font-medium my-4 lg:text-lg text-sm leading-tight text-[#061927]'>Get a tailored walkthrough of our platform's features and capabilities.</li>
                        <li className='ff-mulish font-medium my-4 lg:text-lg text-sm leading-tight text-[#061927]'>Receive answers to all your questions in real time.</li>
                    </ul>
                </div>
                <p className='ff-mulish font-medium lg:text-lg text-sm leading-tight text-[#061927]'>Sign up <a href='your-link-here' className='text-blue-500 underline'>here</a> today and take the first step towards building an agile, future-ready workforce with Third Bracket.</p>
            </div>
        )
    },
    {
        question: "How often does Third Bracket release new features and updates?",
        answer: (

            <div className='ff-mulish font-medium my-4 lg:text-lg text-sm leading-tight text-[#061927]'>
                At Third Bracket, we are committed to continuously enhancing our platform to meet the evolving needs of our clients. We release new features and updates <b>on a quarterly basis</b> , ensuring that our users have access to the latest functionalities, improvements, and security updates. Additionally, we listen closely to customer feedback and may release smaller, incremental updates between major releases to address specific needs or requests.
                Our goal is to ensure that Third Bracket remains cutting-edge, helping your organization stay ahead of workforce trends and improve operational efficiency.

            </div>
        )
    },
    {
        question: "Does Third Bracket offer analytics and reporting for HR metrics?",
        answer: (
            <>
                <h4 className='font-semibold ff-mulish text-sm lg:text-lg text-[#191919]'>
                    Yes, Third Bracket offers robust analytics and reporting capabilities that provide actionable insights into key HR metrics. Our platform delivers comprehensive reports and dashboards covering various aspects of the employee lifecycle, including:

                </h4>
                <ul className='list-disc pl-5 space-y-2'>
                    <li className='ff-mulish font-medium my-4 lg:text-lg text-sm leading-tight text-[#061927]'><strong className='ff-mulish'>Hiring Metrics: </strong>Time-to-hire, cost-per-hire, and candidate quality.
                    </li>
                    <li className='ff-mulish font-medium my-4 lg:text-lg text-sm leading-tight text-[#061927]'><strong className='ff-mulish'>Cultural Fitment Insights:</strong> Alignment scores and team compatibility analysis.</li>
                    <li className='ff-mulish font-medium my-4 lg:text-lg text-sm leading-tight text-[#061927]'><strong className='ff-mulish'>Skill Profiling:</strong>  Employee skill gaps, strengths, and development needs.
                    </li>
                    <li className='ff-mulish font-medium my-4 lg:text-lg text-sm leading-tight text-[#061927]'><strong className='ff-mulish'>Upskilling Impact: </strong> Learning progress, training effectiveness, and skill advancement.

                    </li>
                    <li className='ff-mulish font-medium my-4 lg:text-lg text-sm leading-tight text-[#061927]'><strong className='ff-mulish'>Career Development:</strong>  Career progression trends, retention rates, and employee growth.

                    </li>
                </ul>
                <p className='ff-mulish font-medium my-4 lg:text-lg text-sm leading-tight text-[#061927]'>With Third Bracket’s analytics, you can make data-driven decisions to optimize your HR processes, enhance employee engagement, and improve overall business performance.
                </p>
            </>
        )
    }
];

const IntegrationAccordion = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div>
            {questions.map((item, index) => (
                <div key={index} className={'accordian cursor-pointer'} onClick={() => toggleAccordion(index)}>
                    <div className='flex flex-row items-center justify-between py-4 border-b border-b-[#DFDFDF]'>
                        <h3 className='text-[#191919] font-semibold lg:text-[26px] text-base ff-pop leading-tight flex-1 lg:max-w-full max-w-[80%]'>{item.question}</h3>
                        <span className={`${openIndex === index ? 'rotate-45 transition-all' : 'transition-all'} w-5 h-5 lg:w-10 lg:h-10`}>
                            <img src={plus} alt="Expand" />
                        </span>
                    </div>
                    <div className={openIndex === index ? 'block' : 'hidden'}>
                        <div className='ff-mulish font-medium my-2 lg:text-lg text-sm leading-tight text-[#061927]'>{item.answer}</div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default IntegrationAccordion;
